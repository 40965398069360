import React, { useState } from "react";
import Page from "../../components/Page";
import OHIFGalleryHeader from "../../components/OHIFGalleryHeader";
import SEO from "../../components/SEO";
import { StaticImage } from "gatsby-plugin-image";

function BehindTheScene() {
  return (
    <Page>
      <SEO title="Modes | OHIF" />
      <main className="my-16">
        <div>
          <div>
            <OHIFGalleryHeader />
          </div>
          <div className="flex flex-col px-12 py-16 space-y-8 text-white">
            <h2 className="text-5xl text-blue-100">How modes work</h2>
            <div>
              A mode can be thought of as a viewer app configured to perform a
              specific task, such as tracking measurements over time, 3D
              segmentation, a guided radiological workflow, etc. Addition of
              modes enables application with many applications as each mode
              become a mini app configuration behind the scene. Upon
              initialization the viewer will consume extensions and modes and
              build up the route desired, these can then be accessed via the
              study list, or directly via url parameters. OHIF-v3 architecture
              can be seen in the following:
            </div>
            <div className="max-w-xl mx-auto">
              <StaticImage
                alt={"Modes architecture"}
                src={"../../images/modes-arch.png"}
              />
            </div>

            <div>
              You can read more about modes in the {""}
              <span className="text-blue-100">
                <a href="https://docs.ohif.org/platform/modes/index">
                  documentation page.
                </a>
              </span>
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}

export default BehindTheScene;
